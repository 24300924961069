var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("setup-table", {
    attrs: {
      title: _vm.showHidden ? "Hidden Driver" : "Driver",
      items: _vm.items,
      headers: _vm.headers,
      loading: _vm.loading,
      clearItem: !_vm.showForm || !_vm.editedItem.id,
      showSearch: "",
      pagination: "",
    },
    on: {
      createItem: function ($event) {
        return _vm.createItem()
      },
      editItem: _vm.editItem,
      deleteItems: _vm.deleteItems,
    },
    scopedSlots: _vm._u(
      [
        _vm.showForm
          ? {
              key: "itemForm",
              fn: function () {
                return [
                  _c("driver-form", {
                    ref: "driverForm",
                    attrs: {
                      value: _vm.editedItem,
                      config: _vm.config,
                      zoneItems: _vm.zoneItems,
                      designationItems: _vm.designationItems,
                      positionItems: _vm.positionItems,
                      indicatorItems: _vm.indicatorItems,
                    },
                    on: { submit: _vm.saveItem, closeForm: _vm.closeForm },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: `item.synced`,
          fn: function ({ item }) {
            return [
              item.synced
                ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-sync")])
                : _vm._e(),
            ]
          },
        },
        {
          key: `item.ft`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(item.fulltime ? "Full-Time" : "Part-Time")),
              ]),
            ]
          },
        },
        {
          key: `item.status`,
          fn: function ({ item }) {
            return [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(_vm._s(item.active ? "Active" : "Inactive")),
              ]),
            ]
          },
        },
        {
          key: `item.type`,
          fn: function ({ item }) {
            return [
              item.schoolId
                ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-school")])
                : _vm._e(),
              item.depotId
                ? _c("v-icon", { attrs: { small: "" } }, [_vm._v("mdi-bus")])
                : _vm._e(),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }